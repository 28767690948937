  .google-map {
    width: 100% !important;
   /*  height: 60vh; */
   height: 30vh;
  }
  
  .pin {
    display: flex;
    align-items: center;
    width: 180px !important;
    color: #edcd75;
  }
  
  .pin-icon {
    font-size: 4rem;
  }
  
  .pin-text {
    font-size: 1em !important;
    color: rgb(151, 151, 151) !important;
    font-weight: bold;
  }
  
  @media screen and (min-width: 799px) {
    .google-map {
      height: 40vh;
      
    }
  
    .pin {
      width: 15vw;
    }
  
    .pin-icon {
      font-size: 10vw;
    }
  }