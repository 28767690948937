@media only screen and (min-width: 768px)  {
body{
    font-family: 'jannaLT' ;
}
/* container of service... */
.wrapper {
    height: 700px;
    margin: 0 auto;
    
}
.service-container , .premiumJewelry .service-containers{
    display: block;
    padding-top: 10%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-color: white;  
    background-size: cover ;
    background-image: url("https://i.ibb.co/K5C4MYj/mockups-design-com.jpg")!important  
}
[dir="rtl"] .standard-head{
    text-align: right;
    padding-right: 4.5em;
}
 
.services{
    align-content: center;
    padding: 6em !important;
    background-color: rgba(33, 44, 38, 0.952);
}
.services-clear{
    align-content: center;
    padding: 6em !important;
}
.services h1{
    color: white;
    /*  margin-left: 7.1em !important; */
    font-size: 2em;
}
.services p{
    color: white;
    font-size: 15px;
}
.service-body{
    background-color: white;
    padding-left: 0!important;
    font-size: 15px;
}
.service-body li{
    cursor: pointer;
}
.service-body-list{
    background-color: white;
    padding-left: 0!important;
}
.service-body a:hover{
    color: #edcd75;
}
.service-body-list ul{
    padding-bottom: 4em;
    padding-left: 0 !important;
}
.h-head{
    border: 1px #F0F0F0 solid;
    color: black !important;
    font-size: 1.3em;
    text-align: center;
    padding: 1em;
    /* font-weight: 700; */
}
.nav-link--active{
    color: #edcd75 !important;
    text-decoration: underline !important;
} 
.h-head:visited{
    color: black;
}
.service-body ul{
    padding-left:0 !important;
    padding-right:0 !important;
    display: block;
    margin:0 !important;
}

/* features of the services part  */
.features-services{
    background-color: #F0F0F0;
    padding: 6em 0 6em 0;
    font-size: 15px;
    color: gray;
}
.features-services h4{
    color: black !important;
    font-size: 20px;
}    
.features-services p{
    margin-bottom: 0 !important;
}

/* Diamond Report part */
.diamond-body{ 
    background-color: #F0F0F0;
    color: gray;
    font-size: 15px;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.diamond-body h1{
    color: black;
    margin-top: 0 !important;
    margin-right: 0 !important;
    margin-left: -.57em !important;
}
.diamond-body .li-diamond{
    padding: 0 !important;
    margin-bottom: 0 !important;
    font-size: 15px;
}
.services-img{
    display: flex;
    align-items: center;
}
.diamond-image{
    background-image: url("https://i.ibb.co/LQ32tWH/Standard-Diamond-Report-Inside.png");
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain;
}
.player-wrapper-service {
    position: relative;
}

.arrow-head{
    color: white;
    font-size: 3em !important;
    padding-top: 0 !important;
    margin-top: -32px !important;
    padding-bottom: 0.5em;
    
}
/* gemstone report css part */
.Gemstone-body{
    background-color: #F0F0F0;
    font-size: 15px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    color: black !important;
}
.bg-white{
    background-color: white;
}
.Gemstone-body h1{
    color: black;
    margin-left: .57em !important;
}
.gemhead{
    margin-left:1em !important;
    
}
.li-gemstone{
    padding-left:0 !important;
    margin-left:1em;
    display: block;
}
.li-gemstone li{
    font-size: 15px;
}
/* gemstone. standardreport part */
.standardreport-main{
    background-image: linear-gradient(rgba(33, 44, 38, 0.70), rgba(33, 44, 38, 0.70)),
                url("https://i.ibb.co/J5HVJ2N/Standard-Report-Cover.png");   
    background-position: center !important;
    background-size: contain !important;
    padding: 6em 9em 6em 11em;
    color: white;
    font-size: 15px;
}
.service-video{
    background-color: #212d27;
}
.Depending{
    font-size: .7em ;
}
.stand-sec-p{
    color: white;
    font-size: 15px;
}
.standardrepo{
    background-color: #F0F0F0;
    padding-top: 5em;
    padding-bottom: 5em;
    padding-right:0;
    padding-left:0
}
.standardrepo h2{
    color: black;
}
.Depending-s{
    font-size: .7em ;
    color: gray;
}
.standrepo-p{
    color: gray;
    font-size: 15px;
}
.standard-img{
    background-image: url("https://i.ibb.co/SDTnDNF/Standard-Gemstone-Report-Inside.png");
    padding: 20em;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain ;
    min-width: 50%;
}
.Details{
    background-color: #F0F0F0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-top: 30px;
}
.Details h1{
    color: black;
}
.Details p{
    color: gray;
    font-size: 15px;
}
.detail-body{
    color: gray;
    font-size: 15px;
}
.new-tech{
    background-color: #F0F0F0;
    color: gray;
    text-align: center;
    padding-bottom: 10em;
    padding-top: 5em;
}
.report-image{
    max-width: 50%;
    margin-top: -14rem !important;
    /* padding-top: 5em; */
}

/* card report part */
.cardreport-main{
    background-image: linear-gradient(rgba(33, 44, 38, 0.50), rgba(33, 44, 38, 0.50)),
                url("https://i.ibb.co/3TSmzwk/cardreport-bg.png");   
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 6em 9em 6em 11em;
    color: white;
    font-size: 1em;
}
.cardrepo{
    background-color: #F0F0F0;
    padding-top: 5em;
    padding-bottom: 5em;
}
.cardrepo h2{
    color: black;
    margin-left: 7em !important;
}
.card-img{
    background-image: url("https://i.ibb.co/9y05649/Card-Gemstone-Report-Front.png");
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain ;
    size: 120px !important;
}
.card-report-image{
    max-width: 40%;
    margin-top: -19rem !important;
    padding-top: 5em;
}

/* jewelry part */
.Jewelry-body{
    background-color: #F0F0F0;
    padding: 6em 10em 6em 10em;
    color: gray;
    font-size: 1em !important;
    padding-bottom: 2em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.Jewelry-body h1{
    color: black;
    margin-left: .57em !important;
}
.jewelry-table{
    margin-top: 5em;
    margin-bottom: 4em;
    padding: 0 !important;
    background-color: white;
    margin-left: 2em !important;
    background-color: white;
    padding-left: 0!important;
}

/* jewelry standard part */
.standard-jewlery-report-main{
    background-image: linear-gradient(rgba(33, 44, 38, 0.50), rgba(33, 44, 38, 0.50)),
                url("https://i.ibb.co/J5HVJ2N/Standard-Report-Cover.png");   
    background-position: center !important;
    background-size: contain !important;
    padding: 6em 9em 6em 11em;
    color: white;
    font-size: 1em;
}
.standard-jewelry-img{
    background-image: url("https://i.ibb.co/yy279mX/Standard-Jewelry-Report-Inside.png");
    padding: 20em;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain ;
    min-width: 50%;
}
.standard-jewelry-report-image{
    max-width: 50%;
    margin-top: -20rem !important;
    padding-top: 5em;
}
.standard-jewelry-repo{
    background-color: #F0F0F0;
    padding-top: 5em;
    padding-bottom: 5em;
}
.standard-jewelry-repo h2{
    color: black;
    margin-left: 7em !important;
}
/* large jewelry part */
.largeJewelry-main{
    background-image: linear-gradient(rgba(33, 44, 38, 0.50), rgba(33, 44, 38, 0.50)),
                url("https://i.ibb.co/sqnbq2C/Large-Jewelry-Report-Cover.png");   
    background-position: center !important;
    background-size: contain !important;
    padding: 6em 9em 6em 11em;
    color: white;
    font-size: 1em;
}
.largerepo{
    background-color: #F0F0F0;
    padding-top: 5em;
    padding-bottom: 5em;
}
.largerepo h2{
    color: black;
    margin-left: 7em !important;
}
.large-img{
    background-image: url("https://i.ibb.co/XCvHZLn/Large-Jewelry-Report-Inside-1.png");
    padding: 20em;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover ;
    min-width: 50%;
}
.large-image{
    max-width: 60%;
    margin-top: -14rem !important;
}

/* Premium jewelry report part */
.Premiumreport-main{
    background-image: linear-gradient(rgba(33, 44, 38, 0.50), rgba(33, 44, 38, 0.50)),
                url("https://i.ibb.co/vDPhdym/PREMIUM-BOX.png");   
    background-position: center !important;
    background-size: contain !important;
    padding: 6em 9em 6em 11em;
    color: white;
    font-size: 1em;
}
.premium-img{
    background-image: url("https://i.ibb.co/StGcWwZ/IMG-1010.jpg");
    padding: 20em;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain ;
    min-width: 50%;
}
.premiumrepo{
    background-color: #F0F0F0;
    padding-top: 5em;
    padding-bottom: 5em;
}
.premiumrepo h2{
    color: black;
    margin-left: 7em !important;
}
.premium-image{
    max-width:30%;
    margin-top: -16rem !important;
    padding-top: 5em;
    padding-bottom: 2em;
}
/* card jewelry report part */
.Cardreport-main {
    background-image: linear-gradient(rgba(33, 44, 38, 0.50), rgba(33, 44, 38, 0.50)),
                    url("https://i.ibb.co/L9GP9R6/cardjewelry-main.png");   
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 6em 9em 6em 11em;
    color: white;
    font-size: 1em;
}
.card-jewelry-img{
    background-image: url("https://i.ibb.co/6RyGKgy/Card-Jewelry-Report-Front.png");
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain ;
    size: 120px !important;
}
/* diamond screening part */
.screening-img {
    background-image: url("https://i.ibb.co/F871Drk/mockups-design-com.jpg");   
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 19em 0 13em 10em;
    color: white;
    font-size: 1em;
}
.screening-service-body{ 
    background-color: #F0F0F0;
    padding: 6em 10em 6em 10em;
    color: gray;
    font-size: 1em !important;
    padding-bottom: 2em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.screening-service-body h1{
    color: black;
    margin-left: .57em !important;
}
.screening-body{
    padding-top: 1.5em;
    padding-left: 3.5em !important;
}

/* diamond on-site part */
.onsite-img {
    background-image: url("https://i.ibb.co/F871Drk/mockups-design-com.jpg");   
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 19em 0 13em 10em;
    color: white;
    font-size: 1em;
}
.onsite-body{
    padding-top: 1.5em;
    padding-left: 3.5em !important;
}
.onsite-body h1{
    padding-bottom: .5em;
}
.on-site-body{ 
    background-color: #F0F0F0;
    padding: 6em 10em 6em 10em;
    color: gray;
    font-size: 1em !important;
    padding-bottom: 2em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.on-site-body h1{
    color: black;
    margin-left: .57em !important;
}
/* diamond sealing report part */
.sealing-image {
    background-image: url("https://i.ibb.co/pKVjghL/Diamond-Sealing.png");   
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 19em 0 13em 10em;
    color: white;
    font-size: 1em;
}
.sealing-body{
    padding-top: 1.5em;
    padding-left: 3.5em !important;
}
.sealing-body h1{
    padding-bottom: .5em;
    text-align: left;
}
.diamond-sealing-body{
    background-color: #F0F0F0;
    padding: 6em 10em 6em 10em;
    color: gray;
    font-size: 1em !important;
    padding-bottom: 2em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.diamond-sealing-body h1{
    color: black;
    margin-left: .57em !important;
}

/* container of service... */
/* .serv-head{
    display: flex;                 
    flex-direction: column;         
    justify-content: space-between; 
        display: block;
} */
.serv-head h1{
    margin-bottom: 0.5em !important;
}
[dir="rtl"] .serv-head{
    text-align: right;
}
/* [dir="rtl"] .services h1{
    margin-right: 7.1em !important;
} */
/* features of the services part  */
[dir="rtl"] .features-services{
    text-align: right;
}
/* Diamond Report part */
[dir="rtl"] .diamond-body{
    text-align: right;
}
[dir="rtl"] .diamond-body h1{
    margin-right: -.57em !important;
}
/* gemstone report css part */
[dir="rtl"] .Gemstone-body{
    text-align: right;
}
[dir="rtl"] .MT{
    text-align: right;
}

/* gemstone. standardreport part */
[dir="rtl"] .standardreport-main{
    text-align: right;
}
[dir="rtl"] .standardrepo{
    text-align: right;
    padding-right: 7em;
}
[dir="rtl"] .detail-body{
    text-align: right;
    
}
[dir="rtl"] .standrepo-p{
    padding: 0;
}

/* card report part */
[dir="rtl"] .cardreport-main{
    text-align: right;
}

[dir="rtl"] .new-tech{
    text-align: center !important;
}

/* jewelry standard part */
[dir="rtl"] .standard-jewelry-head{
    text-align: right;
}
[dir="rtl"] .standard-jewelry-repo{
    text-align: right;
    padding-right: 10em;
}

/* large jewelry part */
[dir="rtl"] .largeJewelry-main{
    text-align: right;
}
[dir="rtl"] .largerepo{
    text-align: right;
    padding-right: 10em;
}

/* Premium jewelry report part */
[dir="rtl"] .Premiumreport-main{
    text-align: right;
}
[dir="rtl"] .premiumrepo{
    text-align: right;
    padding-right: 10em;
}

/* card jewelry report part */
[dir="rtl"] .Cardreport-main {
    text-align: right;
}
[dir="rtl"] .cardrepo{
    text-align: right;
    padding-right: 10em;
}

/* diamond sealing part */
[dir="rtl"] .diamond-sealing-body{
    text-align: right;
}
/* diamond screening part */
[dir="rtl"] .screening-service-body{
    text-align: right;
}
/* diamond on-site part */
[dir="rtl"] .on-site-body{
    text-align: right;
}

}
