@font-face {
  font-family: 'jannaLT';
  src: local('JannaLT'), url(./fonts/JannaLT-Regular.ttf) format('truetype');
  src: local('JannaLTBold'), url(./fonts/JannaLTBold.ttf) format('truetype') font-weight-bold;
};
nav{
  background-color: transparent;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: .5rem 1rem;
  border-radius: 0% !important;
  margin-bottom: 0% !important;
}
.fixed-top{
  background-color: transparent;
}
.fixed-top.active {
  background-color: #212d27;
}
li{
  display:inline;
  padding-right:1em;
  font-size: .8em;
}
nav .navbar-nav li a{
  color: white !important;
  text-decoration: none;
}

button, .btn{
  color: black;
  background-color: #edcd75;
  font-size: small;
}
.navbar-btn{
  color: black;
  background-color: #edcd75 !important;
  font-size: small;
  align-items: flex-start;
}

.intro-container{
  color: white;
  padding-bottom: 4em;
}
.intro-container h1{
    margin-top: -.5em !important;
    font-size: 4.3em;
}
.intro h1{
  padding-left: 10px;
}
.intro p{
  padding-left: 15px;
}
[dir="rtl"] .intro h1{
  padding-right: 10px;
}
[dir="rtl"] .intro p{
  padding-right: 15px;
}
[dir="rtl"] .risk h2{
  text-align: right;
  padding-right: 11px;
}
.satis-image{
  max-width: 1%;
  margin-top: -8rem !important;
}
.details-image{
  max-width: 75%;
}
.info-container {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}
.gemstory{
  background-color: #212d27 !important;
  align-content: center;
  padding-top: 2em !important; 
  padding-bottom: 2em !important; 
}
.gemstory h2{
  color: white;
}
.contacticon{
  overflow: hidden;
  vertical-align: middle;
  vertical-align: top;
  margin: 0 20px 0 0 ;
}
.gem-bg{
  color: gray !important;
  text-align: center;
  align-items: center;
  background-color: #212d27 !important;
  /* padding-top: 75px;  */
  padding-bottom: 20px;
}
.gembody{
  font-size: 1em;
  align-items: center;
}

.work{
  background-color: #f1f1f1;
  color: gray;
  /* margin-left: auto !important; */
}
.work-head h2{
  color: black;
  padding-left: 1% !important;
}
.work-head p{
  font-size: 15px;
}
[dir="rtl"] .work-head h2{
  padding-right: 3% !important;
}
.card{
  border-radius: 1em !important;
  text-align: center;
}

.workicon {
  color:black;
  font-size: x-large;
}

.work-body{
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: 0 !important;
  margin-left: 6% !important;
}
.work-head{
  text-align: left;
}

.work-end{
  text-align: left;
  font-size: 15px;
}
.work{
    padding:14.2%;
}
.sat-h{
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: 0 !important;
  margin-left: 6% !important;
  text-align: left;
  font-size: 1.9em !important;
}

.details{
  background-color: #f1f1f1;
}
.det-h2{
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: 0 !important;
  margin-left: 6% !important;
  text-align: right;
  font-size: 1.9em !important;
}
footer{
  /* border-top: .1px white solid; */
  background-color: #212d27 !important;
  padding-top:.5em ;
  display: unset;
  width:100%;
}
footer a{
  color: white !important;
  text-decoration: none;
  padding: .5%;
}
.footer-links{
  text-align: left;
  padding-left: 70px !important;
}
[dir="rtl"] .footer-links{
  text-align: right;
  padding-right: 50px;
}
[dir="rtl"] .footer_p{
  text-align: right;
}
.email-subsribe{
  color: white;
}
.footer-start{
  text-align: left;
  font-size: .8em;
  color: gray;
}
.footer-start h5{
  margin: 0 !important;
  padding-left: .6em;
  color: white;
  font-size: 2em;
}
.footer-start p{
  padding-top: .5em;
}
footer li{
  display:block;
  text-align: left;
  padding-bottom: 2em;
  font-weight: bolder;
}

hr{
  background-color: gray ;
}
.join{
  text-align: right;
}
[dir="rtl"] .join{
  text-align: left;
  padding-left: 0;
}
.join p{
  color: white;
}

.footer-copyright{
  font-size: .6em;
  color: white !important;
}
.iconlog{
  text-align: right;
  margin-right: 0 !important;
  margin-left: 6% !important;
  align-items: flex-end !important;
}
.subscribe_form input{
  background-color: #212d27;
  border-radius: 5px !important;
  color: white;
}

[dir="rtl"] .social .icon-footer{
  text-align: right;
}
.subscribe_form button{
  background-color: #edcd75;
}
.fa-stack{
  font-size: 1.5em;
  text-align: end;
  align-items: flex-end
}
/* terms part */
.TERMS{
  margin-top: 60px;
  margin-bottom: 60px;
  /* border: 1px solid black; */
  padding: 20px;
  font-size: 15px;
  background-color: #f1f1f1;

}
.TERMS li{
  font-size: 15px;
}
[dir="rtl"] .TERMS {
  text-align: right ;
}

.terms-header{
  margin-top: 40px !important;
  text-align: center ;
}

.terms-a{
  padding: 10px;
  cursor: pointer;
}