@media (max-width: 767.98px){
/* home intro part */

body{
margin: 0 !important;
padding: 0;
font-family: 'jannaLT' ;
}
.navbar-dark .navbar-toggler {
    background-color: #212d27;
}

.navbar-expand , .navbar-expand-lg, .expanded{
    background-color: #212d27 !important;
}
.navbar.active,.navbar-light{
    background-color: #212d27 !important;
}
.navbar-toggler , .collapsed{
    color: #C0C0C0 !important;
    border-color: #C0C0C0 !important;
}
.intro {
    padding-top: 110% !important;
}
.wrapper-intro{
    height: 1275px;
}
.wrapper-risk{
    height: 1950px;
}
[dir="rtl"] .wrapper-risk{
    height: 1800px;
}
[dir="rtl"] .wrapper-intro{
    height:1200px;
}
[dir="rtl"] .intro{
    padding-right: 1.5em;
}
.intro h1{
    font-size: 2em;
    padding-left: 1em;
}
.intro p {
    padding-left: 2.2em;
}
.intro button{
    margin-left: 1.4em;
}

[dir="rtl"] .navbar-ul{
    text-align: right;
    padding-right: 20px;
}
[dir="ltr"] .navbar-ul{
    padding-left: 40px;
}
[dir="rtl"] .navbar-ul .home{
    padding-right: 0 !important;
}
[dir="rtl"] .navbar-ul .home{
    padding-left: 0 !important;
}
.footer-divider{
    position: relative;
    padding-top: 1em;
    
}
.footer-divider:before { content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    transform: translateY(-50%);
        }
    
        .footer-divider:after { content: '';
    position: absolute;
    border-right: 1px white solid;
    height: 100%;
    width: 100%;
    transform: translateX(-80%);
        }
[dir="rtl"]  .footer-divider:after { content: '';

    transform: translateX(-10%);
        }

.footer .img-fluid{
    max-width: 150px;
}

.footer .navbar-nav li{
    text-align: center !important;
}
.carousel-caption-item{
    padding-top: 0 !important;
}
/* The Risk of Buying Uncertified Gems part */
.risk{
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-image: linear-gradient(rgba(33, 44, 38, 0.8), rgba(33, 44, 38, 0.9)),
                    url("https://i.ibb.co/FXVvw2f/Photo2.jpg");
    color: rgb(218, 216, 216) !important;
}
.risk p{
    color:rgb(218, 216, 216) !important;
    font-size: 15px !important;
}
.home-card{
    margin-top: 10em; 
}

[dir="rtl"] .risk h2{
    text-align: right !important;
}
[dir="rtl"] .risk-body{
    text-align: right !important;
}

.risk h2{
    text-align: left !important;
    padding-left: 1em;
    padding-right: 2em;
}
.risk-body{
    text-align: left !important;
    color:rgb(218, 216, 216) ;
}
.risk-image {
    display: none;
}
.img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.carousel-caption-home h1{
    font-size: 15px;
}
.carousel-caption-item{
    padding-top: 100px !important;
    background-color: #212d27 ;
}

.carousel-caption-home {
    bottom:0% !important;
    }
.carousel-caption-home p{
    font-size: 10px;
}
.img-container .img-fluid{
    flex-shrink: 0;
    min-width: 250%;
    min-height: 80vh;
}

.language-switcher{
    margin-left:0 !important;
    margin-right:0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
    .language-switcher select{
    margin-left:0 !important;
    margin-right:0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
/* how its work part */
.work{
    padding: 4em 2em 4em 2em;
}
.work-head h2{
    padding:0;
}
.work-head .nav-link button {
    padding: 0;
    margin: 0 !important;

}
[dir="rtl"] .work-head button{
    margin-left: 0 !important;
}
[dir="rtl"] .work-head {
    justify-content:right !important;
}
[dir="rtl"] .work p , .work-end{
    margin-right: 0;
}
[dir="rtl"] .work h2{
    margin-right: 0;
}
.work .work-head .col-12 h2{
    padding-left: 1.3em !important;
}
[dir="rtl"] .work .work-head .col-12{
    padding-right: 2em !important;
}
.work p{
    bottom: 2.5em;
    top: 1em;
}
.work button{
    padding: .5em;
    margin-left: 4em !important ;
    padding-right: 2em !important ;
    padding-left: 2em !important ;
}
.work-end{
    padding-left: 2em !important;
}
/* satisfaction part */
.satisfaction{
background-color: #F0F0F0;
padding: 1em;
}
.satis-image{
    display: none;
}
/* details container part */
.details{
    padding-top: 4em;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-image: linear-gradient(rgba(33, 44, 38, 0.70), rgba(33, 44, 38, 0.8)),
                    url("https://i.ibb.co/X3SWJnx/Photo30.jpg");
    background-position: center !important;
    color: white;
}
.details-body{
    color: rgb(218, 216, 216) !important;
}
.details-image{
    display: none;
}
/* TheLaboratory part */
.fSocial{
    display: none;
}
.laboratory-intro{
    padding-top: 50%;
}
.laboratory-intro-container{
    padding: 2em;
    background: rgba(28, 39, 30, 0.9);
    width:100%;
    color: rgb(151, 151, 151);
    font-size: 1em;
    margin-bottom: 0 !important;
}
.lab-logo{
    display: none;
}
.laboratory-intro-container h1{
    font-size: 1.5em;
}
.laboratory-intro-container p{
    font-size: 15px;
}
.fancy span{
    margin: 0 !important;
}
[dir="rtl"] .footer .fancy{
    justify-content: center;
}
.brand-story{
    font-size: 15px;
}
.MTGL{
    display: none;
}
.laboratory-yellow {
    background-color: white;
    padding-left:1.5em;
    font-size: smaller !important;
}
.laboratory-yellow h3{
    font-size: 15px;
    
}
.diamond-gold{
    display: block;
}

.value-p{
    text-align: center !important;
    padding-bottom: 3em;
    padding-top: 3em;
    font-size: 15px;
    margin-bottom:0
}
.laboratory-third-part{
    height: 183px;
    display: contents
}
.laboratory-third-part img{
    width: 100% !important;
}
.laboratory-fourth-part{
    text-align: center;
    width: 100%;
    background-color: white;
    margin-top: 3em;
}
[dir="rtl"] .laboratory-fourth-part{
    text-align: right;
    width: 100%;
}
.laboratory-fourth-part h1{
    text-align: center !important;
}
.laboratory-fourth-part p{
    text-align: center !important;
    font-size: 15px;
    width: 100%;
}
.laboratory-fourth-part .green-diamond{
    display: none;
}

.wrapper-gem{
    height: 500px;
    }
.laboratory-mission-container{
    padding: 2em !important;
    padding-bottom: 5em  !important;
    color: rgb(151, 151, 151);
    font-size: 1em;
    text-align: left;
    margin:0 !important;
}
[dir="rtl"] .laboratory-mission-container{
    text-align: right;
}
.Accreditation img{
    margin: 2em;
}
.laboratory-fifth-part .lab-sci{
    display: none;
}
[dir="rtl"] .Accreditation img{
    text-align: center !important;

}
.lab-sci{
    margin:0 !important;
}
.Affiliations h1,  .Affiliations p{
    padding-left: 0 !important;
    padding-right:0!important;
    text-align: center !important;
    }

.Affiliations h1{
    text-align: center;
    display: block;
    font-size: 1.5em;
}
/* services part */
.service-container, .premiumJewelry .service-containers{
    padding-top: 50%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover ;
    background-image: url("https://i.ibb.co/K5C4MYj/mockups-design-com.jpg") ;
}
.services{
    padding: 2em;
    background-color: rgb(33, 44, 38) !important;
    width:100%;
    color: rgb(151, 151, 151);
    margin-bottom: 0 !important;
}
[dir="rtl"] .serv-head{
    text-align: right;
}
.services h1{
    color: white;
}
.services p{
    text-align: left;
    font-size: 15px;
    padding-left: 0;
}
[dir="rtl"] .services p{
    text-align: right;
}
.service-body{
    background-color: white;
    padding-left: 0!important;
    padding-right: 0!important;
    font-size: 18px;
}
.service-body-list{
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
}
.service-body-list ul{
    font-size: 18px;
}
[dir="rtl"] .service-body-list{
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.h-head{
    border: 1px #F0F0F0 solid;
    color: black;
    font-size: 1.3em;
    text-align: center;
    padding: 10px;
    font-weight: 700;
}
.h-head:visited{
    color: black;
}
.service-body ul{
    padding-left:0 !important;
    padding-right: 0;
    display: block;
    margin-bottom: 0;
}
/* features of the services part  */
.features-services{
    background-color: white;
    padding: 2em !important;
    padding-bottom: 5em  !important;
    color: rgb(151, 151, 151);
    font-size: 1em;
    text-align: left;
    margin:0 !important;
}
[dir="rtl"] .features-services{
    text-align: right;
}
.features-services h4{
    color: black !important;
    font-size: 1em !important;

}     
.features-services p{
    margin-bottom: 0 !important;
}
/* diamond page part */
.diamond-body{ 
    background-color: #F0F0F0;
    /* padding: 3em; */
    padding-top: 2em;
    color: gray;
    font-size: 18px;
    margin-right: 0 !important;
    margin-left: 0 !important;
}
[dir="rtl"] .diamond-body{ 
    text-align: right;
    margin-left: 0 !important;
    padding-left: 1em;
}

.diamond-body h1{
    color: black;
}
.li-diamond{
    padding-bottom: 2em;
}
    .player{
    width: 100vw !important;
    height: 40vh !important;
    position: relative;
    }
/* gemstone report part */
.Gemstone-body{
    background-color: #F0F0F0;
    padding-top: 2em;
    color: gray;
    font-size: 18px;
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-right: 0 !important;
}
[dir="rtl"] .Gemstone-body{
    text-align: right;
}
[dir="rtl"] .Gemstone-body h1{
    padding-right: 1.8em;
}
.gemhead{
    padding-left: 3em;
    padding-right: 3em;
    padding-bottom: .3em;
}
.Gemstone-body h1{
    color: black;
    padding-left: 1.8em !important;
    padding-right: 0;
}
.li-gemstone{
    padding-left: 2em;
    padding-right: .5em;
    padding-bottom: 2em;
}
[dir="rtl"] .li-gemstone{
    padding-right: 2em;
}

/* gemstone. standardreport part */
.standardreport-main{
    background-image: linear-gradient(rgba(33, 44, 38, 0.70), rgba(33, 44, 38, 0.70)),
                url("https://i.ibb.co/J5HVJ2N/Standard-Report-Cover.png");   
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 7em 2em 3em 2em;
    color: white;
    font-size: 17px;
}
[dir="rtl"] .standardreport-main{
    text-align: right;
}
.service-video{
    background-color: #212d27;
}
.Depending{
    font-size: 1em ;
}
.stand-sec-p{
    font-size: smaller;
    color: #C0C0C0;
}
.standard-head h1{
    font-size: 1.5em;
    margin-top: 0;
}
.card-head h1{
    font-size: 1.5em;
    margin-top: 0;
}
.standardrepo{
    background-color: #F0F0F0;
    padding-top: 2em;
    padding-bottom: 2em;
    text-align: left;
    font-size: 18px;
}
[dir="rtl"] .standardrepo{
    text-align: right;
}
.standardrepo h2{
    color: black;
    padding-left: 1em;
}
.Depending-s{
    font-size: .7em ;
    color: gray;
    padding-left: 2em;
}
.standrepo-p{
    color: gray;
    padding-left: 1em;
}
.standard-img{
    background-image: url("https://i.ibb.co/SDTnDNF/Standard-Gemstone-Report-Inside.png");
    padding: 6em;
    width: 100%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover ;
    margin-left: 1em;
}

.Details{
    background-color: #F0F0F0;
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.Details h1{
    color: black;
}
.Details p{
    color: gray;
    font-size: 1em;
}
.detail-body{
    margin-right:1em !important;
    margin-left:1em !important;
    padding-bottom: 2em;
    padding-right: 0 !important;
    color: gray;
    font-size: 19px;
}
[dir="rtl"] .detail-body{
    text-align: right;
}
.new-tech{
    background-color: #F0F0F0;
    color: gray;
    font-size: 18px;
    text-align: center !important;
    padding: 1em 2em 8em 2em;
}
.report-image{
    max-width: 100%;
    margin-top: -13rem !important;
}
/* card report part */
.cardreport-main{
    background-image: linear-gradient(rgba(33, 44, 38, 0.50), rgba(33, 44, 38, 0.50)),
    url("https://i.ibb.co/3TSmzwk/cardreport-bg.png");   
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 7em 2em 3em 2em;
    color: white;
    font-size: 17px;
}
[dir="rtl"] .cardreport-main{
    text-align: right;
}
[dir="rtl"] .cardrepo{
    text-align: right;
}
.card-img{
    background-image: url("https://i.ibb.co/9y05649/Card-Gemstone-Report-Front.png");
    padding: 6em;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover ;
}
.Details{
    background-color: #F0F0F0;
    padding: 1em 2em 0em 1em;
    
}
.Details h1{
    color: black;
}
.Details p{
    color: gray;
    font-size: 1em;
}
.card-report-image{
    max-width: 20em;
    margin-top: -14rem !important;
    padding-top: 5em;
}
/* jewelry part */
.jewelry-table{
    background-color: white;
    padding-left: 0!important;
    padding-right: 0!important;
    font-size: 20px;
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.Jewelry-body{
    background-color: #F0F0F0;
    padding-top: 2em;
    color: gray;
    font-size: 18px;
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-right: 0 !important;
    text-align: center !important;
}
[dir="rtl"] .Jewelry-body{
    text-align: right;
}
[dir="rtl"] .Jewelry-body h1{
    padding-right: 1.8em;
}
.Jewelry-body h1{
    color: black;
    padding-left: 1.8em !important;
    padding-right: 0;
}
/* jewelry standard part */
.standard-jewelry-img{
    background-image: url("https://i.ibb.co/yy279mX/Standard-Jewelry-Report-Inside.png");
    padding: 13em;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain ;
    min-width: 50%;
}
.standard-jewelry-head h1{
    font-size: 1.5em;
    margin-top: 0;
}
.standard-jewlery-report-main{
    background-image: linear-gradient(rgba(33, 44, 38, 0.50), rgba(33, 44, 38, 0.50)),
                url("https://i.ibb.co/J5HVJ2N/Standard-Report-Cover.png");   
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 7em 2em 3em 2em;
    color: white;
    font-size: 1em;
}
[dir="rtl"] .standard-jewlery-report-main{
    text-align: right;
}
.standard-jewelry-repo{
    background-color: #F0F0F0;
    padding-top: 2em;
    text-align: left;
}
.standard-jewelry-repo h2{
    color: black;
}
[dir="rtl"] .standard-jewelry-repo{
    text-align: right;
}

.standard-jewelry-report-image{
    max-width: 100%;
    margin-top: -17rem !important;
    padding-top: 5em;
}
/* large jewelry part */
.largeJewelry-main{
    background-image: linear-gradient(rgba(33, 44, 38, 0.50), rgba(33, 44, 38, 0.50)),
                url("https://i.ibb.co/sqnbq2C/Large-Jewelry-Report-Cover.png");   
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 7em 2em 3em 2em;
    color: white;
    font-size: 1em;
}
[dir="rtl"] .largeJewelry-main{
    text-align: right;
}
.large-jewelry-head h1{
    font-size: 1.5em;
    margin-top: 0;
}
.largerepo{
    background-color: #F0F0F0;
    padding-top: 2em;
    text-align: left;
}
.largerepo h2{
    color: black;
}
[dir="rtl"] .largerepo{
    text-align: right;
}
.large-img{
    background-image: url("https://i.ibb.co/XCvHZLn/Large-Jewelry-Report-Inside-1.png");
    padding: 13em;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain ;
}
.large-image{
    max-width: 100%;
    margin-top: -17rem !important;
    padding-top: 5em;
}
/* Premium jewelry report part */
.Premiumreport-main{
    background-image: linear-gradient(rgba(33, 44, 38, 0.50), rgba(33, 44, 38, 0.50)),
                url("https://i.ibb.co/vDPhdym/PREMIUM-BOX.png");   
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 7em 2em 3em 2em;
    color: white;
    font-size: 1em;
}
[dir="rtl"] .Premiumreport-main{
    text-align: right;
}
.premium-jewelry-head h1{
    font-size: 1.5em;
    margin-top: 0;
}
.premiumrepo{
    background-color: #F0F0F0;
    padding-top: 2em;
    text-align: left;
}
.premiumrepo h2{
    color: black;
}
[dir="rtl"] .premiumrepo{
    text-align: right;
}
.premium-img{
    background-image: url("https://i.ibb.co/StGcWwZ/IMG-1010.jpg");
    padding: 13em;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain ;
    margin-bottom: 1em;
}
.premium-image{
    max-width: 80%;
    margin-top: -15rem !important;
    padding-top: 5em;
    padding-bottom: 2em;
}
/* card jewelry report part */
.Cardreport-main {
    background-image: linear-gradient(rgba(33, 44, 38, 0.50), rgba(33, 44, 38, 0.50)),
                    url("https://i.ibb.co/L9GP9R6/cardjewelry-main.png");   
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 7em 2em 3em 2em;
    color: white;
    font-size: 17px;
}
[dir="rtl"] .Cardreport-main {
    text-align: right;
}
.card-jewelry-head h1{
    font-size: 1.5em;
    margin-top: 0;
}
.cardrepo{
    background-color: #F0F0F0;
    padding-top: 2em;
    text-align: left;
}
.cardrepo h2{
    color: black;
}
[dir="rtl"] .cardrepo{
    text-align: right;
    padding-right: 2em;
}
.card-report-image{
    max-width: 80%;
    margin-top: -15rem !important;
    padding-top: 5em;
}
.card-jewelry-img{
    background-image: url("https://i.ibb.co/6RyGKgy/Card-Jewelry-Report-Front.png");
    padding: 8em;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain ;
}
/* diamond screening part */
.screening-img {
    background-image: url("https://i.ibb.co/Vqp7ZqZ/large-J-detail.png");   
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 7em 2em 5em 2em;
}
.screening-service-body{ 
    background-color: #F0F0F0;
    padding: 3em;
    padding-top: 2em;
    color: gray;
    font-size: 18px;
    margin-right: 0 !important;
    margin-left: 0 !important;
}
[dir="rtl"] .screening-service-body{ 
    text-align: right;
}
.screening-service-body h1{
    color: black;
}
/* diamond on-site part */
.onsite-img {
    background-image: url("https://i.ibb.co/RCwt0ww/diamond-image.png");   
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 7em 2em 5em 2em;
}
.on-site-body{ 
    background-color: #F0F0F0;
    padding: 3em;
    padding-top: 2em;
    color: gray;
    font-size: 18px;
    margin-right: 0 !important;
    margin-left: 0 !important;
}
[dir="rtl"] .on-site-body{ 
    text-align: right;
}
.on-site-body h1{
    color: black;
}
/* diamond sealing report part */
.sealing-image {
    background-image: url("https://i.ibb.co/pKVjghL/Diamond-Sealing.png");   
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 7em 2em 5em 2em;
    background-color: #F0F0F0;
}
.sealing-body{
    background-color: #F0F0F0;
    padding: 3em !important;
    padding-top: 2em;
    color: gray;
    font-size: 18px;
}
.diamond-sealing-body{
    margin-right: 0 !important;
    margin-left: 0 !important;
}
[dir="rtl"] .sealing-body{
    text-align: right;
    margin-left: 0 !important;
}
.sealing-body h1{
    color: black;
}

/* Footer part */
.footer{
    background-color: #212d27 !important;
}
[dir="rtl"] .footer{
    text-align: right;
}
.footer-links{
    padding-left: 0 !important;
    font-size: 20px;
}
[dir="rtl"] .footer-links ul {
    padding-right: 0 !important;
}
.footer .join{
    text-align: left;
    padding: .1em;
    padding-top: 2em;
}
[dir="rtl"] .footer .join{
    text-align: right;
}
.logo-footer {
    width: 2em;
}
[dir="rtl"] .logo-footer {
    right: 1.5em;
}
.social, .iconlog{
    float: none !important;
    text-align: center !important;
    justify-content: center !important;
}
.social{
    margin-right: 15%;
}
[dir="rtl"] .social{
    margin-right: 0;
}
[dir="rtl"] .social .icon-footer{
    text-align: center !important;
}
.footer-p{
    font-size: 9px;
}
.footer-p-1{
    font-size: 9px;
}
[dir="rtl"] .footer-p{
    text-align: right !important;
}
[dir="rtl"] .footer-p-1{
    text-align: right !important;
}
.footer .fa-stack{
    right: 3em;
    font-size: 2em;
}
[dir="rtl"] .footer .fa-stack{
    left: 3em !important;
}
/* verify report part */
.verify-report-main{
    padding:2em
}
.verify-report-main h1{
    font-size: 15px;
    color: black;
}
.retailLookup_p2{
    display: none;
}
/* contact part */
.contact_p3{
    display: none;
}
/* terms part */
[dir="rtl"] .TERMS li{
    padding-right: 0 !important;
}
.terms{
    color: black;
}
.TERMS{
    margin-top: 20px !important;
}
.terms-header{
    padding-top: 30px;
}
[dir="rtl"] .terms h1{
    text-align: center;
}
}
@media (max-width: 992px){
    [dir="rtl"] .navbar-ul{
        text-align: right;
    }
}

@media (max-width:768px){
    [dir="rtl"] .language-switcher{
        /* margin-right: 10px; */
        padding-right: 0;
        }
        [dir="ltr"] .language-switcher{
        /* margin-left: 10px; */
        padding-left: 0;
        /* margin-left:auto; */
        }
}