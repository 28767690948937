@font-face {
  font-family: 'jannaLT';
  src: local('JannaLT'), url(./fonts/JannaLT-Regular.ttf) format('truetype');
  src: local('JannaLTBold'), url(./fonts/JannaLTBold.ttf) format('truetype') font-weight-bold;
};
.navbar.active {
  background-color: #212d27 !important;
}
body {
  font-family: "jannaLT" !important;
}
button {
  font-family: "jannaLT" !important;
}
div {
  font-family: "jannaLT" !important;
}
option {
  font-family: "jannaLT" !important;
}
.header,
h1,
h2,
h3,
h4,
h5 {
  font-family: "jannaLT" !important;
  font-weight: bold;
  text-transform: capitalize;
}
.navbar-light .navbar-toggler-icon {
  background-image: url(
    data:image/svg + xml,
    %3csvgxmlns="http://www.w3.org/2000/svg"width="30"height="30"viewBox="0 0 30 30"%3e%3cpathstroke="white"fill="white"stroke-linecap="round"stroke-miterlimit="10"stroke-width="4"d="M4 7h22M4 15h22M4 23h22"/%3e%3c/svg%3e
  );
}
@media (max-width: 767.98px) {
  h1,
  h2,
  h3,
  h4,
  h5 {
    scale: 0.7;
  }
}
.lang-btn {
  background: transparent;
  border: none;
  color: white;
  font-size: 15px;
  text-shadow: 0.5px 1px 1px #212d27;
}
.player-wrapper {
  position: relative;
}
.carousel-caption-home {
  bottom: 30% !important;
}
@media (max-width: 767.98px) {

  .carousel-caption-item {
    padding-top: 100px !important;
  }
  .carousel-caption {
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    scale: 0.7;
  }
}
.carousel-caption-item {
  background-color: #212d27;
}
.carousel {
  width: 100%;
}
.service-carousel .carousel-indicators li {
  display: none;
}
.bg-green {
  background-color: #212d27;
}

[dir="ltr"] .navbar-ul {
  margin-left: auto;
}
[dir="rtl"] .navbar-ul {
  margin-right: auto;
}
li {
  display: inline;
  padding-right: 2em;
  font-size: 0.8em;
}
.navbar-ul .nav-link {
  color: white;
  text-decoration: none;
  text-shadow: 0.5px 1px 1px #212d27;
}

.nav-link--active {
  color: #edcd75 !important;
  text-decoration: underline;
}

button,
.btn {
  color: black;
  background-color: #edcd75;
  font-size: small;
}

.navbar-collapse {
  overflow: hidden;
}
.intro-container {
  color: white;
  padding-bottom: 4em;
}
.intro-container h1 {
  margin-top: -0.5em !important;
  font-size: 4.3em;
}
/* .risk-image{
  height: 450px;
  background-position: left !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
} */

.details-image {
  max-width: 100%;
  float: right;
}
.branch-i {
  margin-left: 0;
  margin-right: 0.5em;
}
[dir="rtl"] .branch-i {
  margin-left: 0.5em;
}
.info-container {
  padding-top: 100px;
  padding-bottom: 50px;
  text-align: center;
}
.gemstory {
  background-color: #212d27 !important;
  align-content: center;
  padding-top: 3em !important;
  padding-bottom: 3em !important;
}
.gemstory h2 {
  color: white;
}
.gemicon img {
  margin-top: 1em;
  margin-bottom: 1em;
  max-width: 100px;
}

.gem-bg {
  color: white !important;
  text-align: center;
  align-items: center;
  background-color: #212d27 !important;
  /* padding-top: 75px;  */
  padding-bottom: 20px;
}

.green-bg {
  background-color: #212d27 !important;
}
.gembody {
  font-size: 15px;
  align-items: center;
  color: white;
}
.risk-body,
.satisfaction-body,
.details-body {
  color: gray !important;
}

.risk-body {
  /* padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: 0 !important;
  margin-left: 6% !important; */
  text-align: start !important ;
  font-size: 15px;
}

.work {
  background-color: #f0f0f0;
  color: gray;
}
.work h2 {
  color: black;
  margin-left: -9.5% !important;
}
.work p {
  margin-left: -5.5% !important;
}
.card {
  border-radius: 1em !important;
  text-align: center;
  padding: 0;
}

.workicon {
  color: gray;
  font-size: medium !important;
}

.work-head h1 {
  text-align: center !important;
  white-space: nowrap !important;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: middle;
}
.work-head {
  text-align: left;
}
.work-head a {
  padding-top: 0;
}
.work-end {
  text-align: left;
  margin-left: -5.5% !important;
}

.sat-h {
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: 0 !important;
  margin-left: 6% !important;
  text-align: left;
  font-size: 1.9em !important;
}
.satisfaction-body {
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: 0 !important;
  margin-left: 6% !important;
  text-align: start;
  font-size: 1.1rem;
}
.details {
  background-color: #f0f0f0 !important;
}
.details-body {
  /* padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: 0 !important;
  margin-left: 6% !important; */
  text-align: left !important;
  font-size: 1.1rem;
}
[dir="rtl"] .details-body {
  text-align: right !important;
}
.det-h2 {
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: 0 !important;
  margin-left: 6% !important;
  text-align: right;
  font-size: 1.9em !important;
}
/* laboratory */
.laboratory-intro {
  display: flex; /* defines flexbox */
  flex-direction: column; /* top to bottom */
  justify-content: space-between; /* first item at start, last at end */
  padding-top: 30%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-image: url("https://i.ibb.co/NSVzwvH/The-Laboratory-1.jpg");
  background-color: white;
}
.transcript {
  font-size: x-small;
}

.wrapper {
  height: 700px;
  margin: 0 auto;
}

.full-width {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: -1;
}
.wrapper-gem {
  height: 350px;
  margin: 0 auto;
}
.brand-story h1 {
  margin-top: 5% !important;
}

.full-width-gem {
  position: absolute;
  left: 0;
  width: 100%;
}
.laboratory-intro-container {
  padding-bottom: 4em;
  padding-left: 10%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  background: rgba(28, 39, 30, 0.9);
  float: left;
  width: 60%;
  line-height: 10px;
  font-size: 15px !important;
  color: white;
  text-align: justify;
  margin-bottom: -10%;
}

.laboratory-intro-container h1 {
  padding-bottom: 10px;
  color: white;
  font-size: 23px !important;
}
.diamond-gold {
  display: none;
}
.value-p {
  margin-bottom: 150px;
  text-align: start;
  padding: 0px;
  color: rgb(168, 165, 165);
}
.laboratory-second-part {
  font-size: 15px !important;
  color: rgb(151, 151, 151);
  padding: 8%;
  padding-bottom: 0;
  padding-top: 5%;
}
.MTGL {
  margin-right: 17em;
  margin-top: 4em;
}
.laboratory-second-part h1 {
  color: black;
  font-size: 23px !important;
}
.laboratory-yellow {
  background-color: rgb(238, 208, 108);
  padding: 40px;
  color: gray;
  font-size: 15px !important;
}
.laboratory-yellow h3 {
  color: black;
  text-transform: uppercase;
}
.fancy span {
  display: inline-block;
  position: relative;
  float: right;
  margin-right: 39px;
  color: white;
}
[dir="rtl"] .fancy span {
  margin-right: 0;
}
.fancy span:before,
.fancy span:after {
  content: "";
  position: absolute;
  height: 5px;
  border-bottom: 1px solid white;
  top: 0;
  width: 80px;
}
.fancy span:before {
  right: 100%;
  margin-top: 5px;
  margin-right: 20px;
}
.fancy span:after {
  display: none;
}
.fancy-f span {
  position: relative;
  float: right;
  color: white;
  margin-right: 20px;
  margin-top: -25px !important;
}
.footer .navbar-nav li {
  /* padding: 0; */
  cursor: pointer;
  margin-left: 50px;
}
[dir="rtl"] .footer .navbar-nav li {
  text-align: right;
  margin-right: 50px;
}
.footer .fancy {
  display: flex;
  justify-content: center;
}
[dir="rtl"] .footer .fancy {
  display: flex;
  justify-content: flex-start;
}
.fancy-f span:before,
.fancy-f span:after {
  content: "";
  position: absolute;
  height: 5px;
  border-bottom: 1px solid white;
  top: 0;
  width: 80px;
}

.fancy-f span:before {
  right: 100%;
  margin-top: 5px;
  margin-right: 20px;
}
.fancy-f span:after {
  display: none;
}

.table-collapse td,
.table-collapse th {
  border: none !important;
  font-size: 15px;
  color: rgb(151, 151, 151);
  text-align: left;
  line-height: 30px;
  vertical-align: top !important;
}
[dir="rtl"] .table-collapse td,
.table-collapse th {
  text-align: right;
}
.table-collapse th {
  font-weight: bold !important;
  color: rgb(54, 54, 54);
}
.card-side {
  background-color: #212d27 !important;
  border-radius: 0.25rem;
}
.card {
  margin-bottom: 30px;
}
.card-icon {
  width: 30px;
  margin-right: 10px;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #e9ecef;
  border-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 25px;
}

.card-header {
  border-radius: 0.5rem !important;
  margin-bottom: 0;
  border: none !important;
  background-color: rgba(0, 0, 0, 0.03);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 0) calc(0.25rem - 0) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card {
  box-shadow: 5px 3px 9px 0 rgba(169, 184, 200, 0.15);
  -webkit-box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
  -moz-box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
}

.laboratory-third-part {
  z-index: -1;
  width: 100%;
  justify-self: center !important;
  height: 500px;
  margin-top: -10rem;
}
[dir="rtl"] .laboratory-third-part {
  transform: scaleX(-1);
}
.laboratory-fourth-part {
  background-color: rgb(240, 235, 235);
  padding-bottom: 4em;
  padding-left: 10%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  line-height: 10px;
  font-size: 15px !important;
  color: gray;
}
.laboratory-fourth-part h1 {
  font-size: 23px;
  margin-bottom: 50px;
  color: black;
}
.laboratory-fourth-part p {
  width: 80%;
}
.laboratory-fourth-part img {
  margin-top: 60px;
}
.laboratory-mission-container {
  padding-top: 5%;
  background: rgba(28, 39, 30, 0.99);
  background-position: contain;
  float: right;
  line-height: 10px;
  font-size: 15px !important;
  color: white;
  text-align: start;
  margin-top: -5% !important;
  padding-left: 15% !important;
  padding-right: 15% !important;
  padding-bottom: 5%;
}
.laboratory-mission-container h1 {
  color: white;
  font-size: 23px !important;
}
.laboratory-fifth-part {
  background-color: white;
  color: rgb(151, 151, 151);
  font-size: 15px !important;
  text-align: right;
}
.laboratory-fifth-part h1 {
  margin-bottom: 50px;
  margin-top: 50px !important;
}
.lab-sci img {
  width: 483px;
  height: 500px;
  z-index: 2;
  margin-bottom: -80px;
  margin-left: -4%;
}
.lab-sci-home img {
  /* position: absolute; Reposition logo from the natural layout */
  width: 400px;
  height: 500px;
  z-index: 2;
  margin-left: -60px;
  position: relative;
  margin-bottom: -210px; /* number here need to be more*/
}
[dir="rtl"] .lab-sci-home img {
  margin-left: 0;
  position: relative;
  margin-bottom: -110px;
}
.lab-sci-home2 img {
  position: absolute; /* Reposition logo from the natural layout */
  width: 400px;
  height: 480px;
  z-index: 2;
  /* margin-right: -60px; */
  /* margin-bottom: -80px; */
}
.home-card {
  height: 150px;
  margin-top: 3em !important;
}

.laboratory-fifth-part .Affiliations {
  margin: 1em;
  margin-top: -2em;
}
.Affiliations h1,
.Affiliations p {
  padding-left: 20% !important;
  padding-right: 20% !important;
  text-align: start;
}

/* retail lookup */
.retail-lookup {
  padding-left: 10%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 0%;
}
.retail-lookup a {
  color: gray;
}
.retail-lookup h1 {
  font-size: 30px;
}
.retail-lookup p {
  color: rgb(151, 151, 151);
  font-size: 15px !important;
}
.scroller {
  height: 244px;
  overflow: auto;
}
[dir="rtl"] .Jeddah,
[dir="rtl"] .Riyadh {
  direction: ltr !important;
  unicode-bidi: embed;
}
[dir="rtl"] .contact_p3 {
  text-align: left !important;
}
[dir="rtl"] .retailLookup_p2 {
  text-align: left !important;
}
.retail-lookup .card {
  font-size: 14px;
}
.retail-lookup .form-control {
  font-size: 14px;
}
.icon-div {
  margin-bottom: 15px;
}
[dir="rtl"] .icon-div {
  text-align: right !important;
}
[dir="rtl"] .retailer-info {
  text-align: right;
}
.retailer-phone {
  text-align: left !important;
  align-content: flex-start;
}
[dir="ltr"] .icon {
  margin-right: 5px;
}
[dir="rtl"] .icon {
  margin-left: 5px;
}
.text-field {
  background-color: rgb(243, 243, 243) !important;
  border: none !important;
  border-radius: 20px !important;
}
.text-field::placeholder {
  color: rgb(151, 151, 151) !important ;
}
.submit-btn {
  background-color: #edcd75 !important;
  color: black !important;
  font-weight: bold;
  text-align: center !important;
  white-space: nowrap;
  display: flex;
  align-content: middle;
  justify-content: center;
  width: 60%;
}
.subscribe input {
  background-color: transparent;
  padding: 0.5em;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  width: 80%;
  border: 1px solid white;
  color: white;
}
.subscribe input:focus {
  outline: none !important;
}
.subscribe button {
  color: black;
  background-color: #edcd75;
  font-size: 15.5px;
  border: none;
  margin-left: -15px !important;
  padding: 0.5em;
  border-radius: 5px;
}
[dir="rtl"] .subscribe input {
  border-left: 1px solid transparent !important;
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
[dir="rtl"] .subscribe button {
  margin-right: -12px;
}
.subscribe span {
  font-family: "jannaLT" !important;
}
.subscribe .error {
  color: tomato;
}
.subscribe .success {
  color: white;
}
.search-field {
  background-color: rgb(243, 243, 243) !important;

  border-radius: 20px !important;
}
.search-field > input {
  border: none !important;
  background-color: transparent;
}
.search-field > input:focus {
  outline: none !important;
}
.image-cropper {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
}
.profile-pic {
  display: inline;
  margin: 0 auto;
  width: 100px;
}
.error-msg {
  color: tomato;
}
.success-msg {
  color: darkgreen;
}
.retailer-list {
  text-align: left;
  cursor: pointer;
}
/* [dir="rtl"] .carousel-container-members{
  direction: ltr;
} */
.carousel-container-members {
  padding-bottom: 5%;
}
.members-img-container {
  width: 250px;
  height: 250px;
  position: relative;
  overflow: hidden;
  /* border-radius: 50%; */
  display: inline-block;
}
.members-img-container .img-fluid {
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}
/* footer */

footer {
  background-color: #212d27 !important;
  /* padding:5.1em 10.1em ; */
  /* display: unset; */
  width: 100%;
  color: white;
  margin-bottom: 0 !important;
}
footer a {
  color: white !important;
  text-decoration: none;
  padding: 0.5%;
}
.footer-links {
  text-align: left;
}
footer .social li {
  margin-top: 5em;
  padding: 0;
}
[dir="ltr"] footer .social li {
  padding-right: 15px;
}
[dir="rtl"] footer .social li {
  padding-left: 15px;
}
footer .social ul {
  padding: 0;
}
.footer-start {
  text-align: left;
  font-size: 0.8em;
  color: gray;
}
.footer p {
  color: rgb(202, 202, 202);
  padding-top: 1em;
}

footer li {
  display: block;
  text-align: left;
  padding-bottom: 2em;
  font-weight: bolder;
  color: white;
}
.social {
  float: left;
}
.social ul {
  margin: 0;
}
[dir="rtl"] .social {
  float: right;
}
[dir="rtl"] .iconlog {
  float: left;
}
.bg-r {
  background-color: red;
}
hr {
  background-color: gray;
}

.footer-copyright {
  font-size: 0.8em;
  color: white !important;
}

.iconlog {
  margin-top: 5em;
  align-items: flex-end !important;
}
.join {
  text-align: end;
  padding-right: 0 !important;
  margin: 0 !important;
}
.join h1,
.join p {
  color: white;
}

.footer .fa-stack {
  font-size: 1.5em;
  text-align: end;
  align-items: flex-end;
}
.social {
  padding: 0;
  margin: 0;
}
/* verify-report-main container */
.verify-report-main {
  padding-left: 10em;
  padding-right: 16em;
  padding-top: 7em;
  padding-bottom: 2em;
}
.verify-report-main h1 {
  font-size: 30px;
  color: black;
  padding-bottom: 0.7em;
}
.verify-report-main p {
  color: gray;
  padding-bottom: 4em;
}
.verifypdf {
  background-image: url("https://i.ibb.co/gmhSS2f/verifypdf.png") !important;
  padding: 50%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

/* Adding RTL layout for RTL languages */
/* intro container ...  */
[dir="rtl"] .intro-container {
  text-align: right;
  padding-bottom: 4em;
}
/* work end align */
[dir="rtl"] .work-end {
  text-align: right;
}
[dir="rtl"] .work h2 {
  margin-right: -9.5%;
}
[dir="rtl"] .work p,
.work-end {
  margin-right: -5.5%;
}
[dir="rtl"] .work-head {
  text-align: right !important;
}
/* Satisfaction part */
[dir="rtl"] .satisfaction-body {
  text-align: right;
  margin-right: 10% !important;
}
[dir="rtl"] .sat-h {
  text-align: right;
  margin-right: 10% !important;
}
/* laboratory-intro-container */
[dir="rtl"] .laboratory-intro-container {
  text-align: right !important;
  float: right;
}
.fSocial {
  margin-top: 2em;
  float: right;
}
.fSocial .fab {
  color: black;
}
[dir="rtl"] .fSocial {
  margin-top: 2em;
  float: left;
}
[dir="rtl"] .laboratory-second-part {
  text-align: right !important;
}
[dir="rtl"] .MTGL {
  margin-right: 10em;
  margin-top: 4em;
}

[dir="rtl"] .laboratory-fourth-part {
  text-align: right !important;
}

[dir="rtl"] .laboratory-fifth-part .Affiliations {
  text-align: start;
}
/* footer logo ... */
[dir="rtl"] .logo-footer a {
  padding-left: 0;
  text-align: right !important;
}

[dir="rtl"] .footer-start {
  text-align: right !important;
}

/* retail lookup part */
[dir="rtl"] .retail-lookup {
  text-align: right;
}

[dir="rtl"] .retailer-list {
  text-align: right !important;
}

/* verify report main part */
[dir="rtl"] .verify-report-main {
  text-align: right;
}
:root {
  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}

.carousel-caption-home {
  text-align: left !important;
  color: white !important;
}
[dir="rtl"] .carousel-caption-home {
  text-align: right !important;
}

.img-gradient img {
  display: block;
  margin: 0 !important;
}
/* linear-gradient(rgba(33, 44, 38, 0.50), rgba(33, 44, 38, 0.50)) */
.img-gradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: -moz-linear-gradient(
    top,
    rgba(33, 44, 38, 0.5) 0%,
    rgba(33, 44, 38, 0.5) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(33, 44, 38, 0.5)),
    color-stop(100%, rgba(33, 44, 38, 0.5))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(33, 44, 38, 0.5) 0%,
    rgba(33, 44, 38, 0.5) 100%
  ); /* Chrome10+,Safari5.1+ */
}
.top-photo .img-fluid {
  height: 75vh;
  object-fit: cover;
  width: 100vw;
}
.recaptcha iframe {
  width: 100%;
}
